import { useEffect, useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { ssoAtom, configAtom, ssoFeatureFlagAtom } from 'src/store/store';
import { err } from 'common/utils';
import type config from 'config';

// SSO SDK Documentation: http://sso-sdk-docs.s3-website-ap-southeast-2.amazonaws.com/
// Set up SSO SDK as a re-usable hook with atom
export const useSDK = () => {
  const [sso, setSSO] = useAtom(ssoAtom);
  const [error, setError] = useState(false);
  const ssoFeatureFlag = useAtomValue(ssoFeatureFlagAtom);
  const appConfig = useAtomValue(configAtom);
  const hasNotInitialised = sso === null;

  // We want to bubble the error so it's handled by an error boundary at least
  if (error) throw error;

  useEffect(() => {
    if (hasNotInitialised && appConfig) {
      initSDK(appConfig, ssoFeatureFlag)
        .then((sdk) => setSSO(sdk))
        .catch((caughtErr) => {
          err(caughtErr);
          setError(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sso, appConfig, ssoFeatureFlag]);

  return sso;
};

const initSDK = async (appConfig: typeof config, ssoFeatureFlag: boolean) => {
  const {
    sso: { clientId, authUrl, settingsUrl },
  } = appConfig;

  // @nine-tech/sso-sdk relies on Web APIs so it can't run on Next server-side
  const SDK = (await import('@nine-tech/sso-sdk/lib/web-factory')).default;

  const sdk = SDK.create({
    clientId,
    loginService: authUrl,
    // @ts-ignore type-def is complaining when this is valid - library concern
    settingsService: settingsUrl,
    forcePkce: true,
    redirectUri: `${window.location.origin}/callback`,
    enabled: ssoFeatureFlag,
  });

  return sdk;
};

export default useSDK;
