import { motion } from 'framer-motion';
import styled from 'styled-components';

// This component is meant to be used when you need an unordered list for a11y
// reasons but you don't want any of the list styling.
export const UnstyledList = styled.ul`
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
`;

export const RowList = styled(UnstyledList)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

export const FlexBreak = styled.div`
  flex-basis: 100%;
`;

export const UnstyledButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
`;

export const StyledIcon = styled.div<{
  $svgHeight?: string;
  $svgWidth?: string;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    height: ${(props) => `${props.$svgHeight}`};
    width: ${(props) => `${props.$svgWidth}`};
  }
`;

export const Overlay = styled(motion.div)`
  background-color: var(--almost-black);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
`;
