import './header.scss';
import classNames from 'classnames';
import Link from 'next/link';
import { useSDK } from 'hooks/sso/useSDK';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';
import { UnstyledList } from 'styles/styledComponents';
import Categories from 'public/assets/icons/categories.svg';
import Chevron from 'public/assets/icons/chevron-down.svg';
import Close from 'public/assets/icons/close.svg';
import Exit from 'public/assets/icons/exit.svg';
import Home from 'public/assets/icons/home.svg';
import Lightning from 'public/assets/icons/lightning.svg';
import Menu from 'public/assets/icons/menu.svg';
import Profile from 'public/assets/icons/profile.svg';
import Search from 'public/assets/icons/search.svg';
import Time from 'public/assets/icons/time.svg';
import Tv from 'public/assets/icons/tv.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { err } from 'common/utils';
import { Header as HeaderAtomType, headerAtom, ssoFeatureFlagAtom, tabbedGridAtom } from 'src/store/store';
import NineNowLogo from 'components/nineNowLogo/NineNowLogo';
import { hasDesktopWidth } from 'common/browser';
import { trackStructEvent } from '@snowplow/browser-tracker';

export enum HeaderLinks {
  HOME = '/',
  LIVE = '/live',
  CATEGORIES = '/genres',
}

export type HeaderType = {
  pathname: string;
  userFirstName: string | undefined;
  isLoggedIn: boolean;
};

const Header = ({ pathname = HeaderLinks.HOME, userFirstName, isLoggedIn }: HeaderType) => {
  const sdk = useSDK();
  const [isNavDrawerVisible, setIsNavDrawerVisible] = useState(false);
  const [isProfileMenuVisible, setIsProfileMenuVisible] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [, setHeaderStoreValues] = useAtom(headerAtom);
  const headerGradientRef = useRef<HTMLDivElement>(null);
  const tabbedGrid = useAtomValue(tabbedGridAtom);
  const ssoFeatureFlag = useAtomValue(ssoFeatureFlagAtom);

  const updateHeaderStoreValues = useCallback(
    ({ headerHeight }: HeaderAtomType) => {
      setHeaderStoreValues({ headerHeight });
    },
    [setHeaderStoreValues]
  );
  const headerRef = useRef<HTMLElement>(null);
  const updateHeaderHeightValue = useCallback(() => {
    headerRef.current && updateHeaderStoreValues({ headerHeight: headerRef.current.clientHeight });
  }, [updateHeaderStoreValues]);

  const handleResize = useCallback(() => {
    try {
      setIsDesktop(hasDesktopWidth());
      updateHeaderHeightValue();
    } catch (error: unknown) {
      err(error as Error);
      setIsNavDrawerVisible(false);
    }
  }, [updateHeaderHeightValue]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    updateHeaderHeightValue();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [updateHeaderHeightValue, handleResize]);

  useEffect(() => {
    // When resizing to desktop, profile menu should be hidden
    // When resizing to mobile, profile menu should always be visible in hamburger menu
    setIsProfileMenuVisible(!isDesktop);

    // When resizing to desktop, close hamburger menu
    if (isDesktop) {
      setIsNavDrawerVisible(false);
    }
  }, [isDesktop]);

  useEffect(() => {
    if (pathname.includes('/live/')) {
      if (headerGradientRef.current) {
        headerGradientRef.current.classList.add('live_page');
      }
    }
  }, [pathname, headerGradientRef, isLoggedIn]);

  const snowplowTrackingForHeaderNavLinks = (
    actionString: string,
    labelName: string,
    destinationURL: string
  ) => {
    trackStructEvent({
      category: 'Global Navigation Header',
      action: actionString,
      label: labelName,
      property: destinationURL,
    });
  };

  const tabIndex = isNavDrawerVisible ? undefined : -1;

  const getSSOPath = (
    route: 'Login' | 'Register' | 'Profile' | 'Device' | 'Terms' | 'Privacy' | 'Logout'
  ): string =>
    sdk?.getSSOPath({
      routeName: route,
      state: { redirect_uri: window.location.pathname },
    });

  return (
    <>
      <header
        ref={headerRef}
        className={classNames('header', {
          'header--visible': (!isDesktop && isNavDrawerVisible) || (isDesktop && isProfileMenuVisible),
        })}
      >
        <button
          className="header__icon_button--mobile-only"
          tabIndex={isDesktop ? -1 : undefined}
          type="button"
          aria-label="menu"
          data-testid="menu-icon-button"
          onClick={() => {
            setIsNavDrawerVisible(!isNavDrawerVisible);
            snowplowTrackingForHeaderNavLinks('show', '', '');
          }}
        >
          <Menu role="button" />
        </button>

        <Link
          className="header__logo"
          href="/"
          onClick={() => snowplowTrackingForHeaderNavLinks('select', 'Logo', '/')}
        >
          <NineNowLogo
            id="9now-header-logo"
            data-testid="nine-header-logo"
            role="img"
            aria-label="9Now Logo"
            width="100%"
            height="100%"
          />
        </Link>

        <div
          className={classNames('header__nav_container', {
            'header__nav_container--visible': isNavDrawerVisible,
          })}
        >
          <button
            className="header__icon_button--mobile-only"
            type="button"
            aria-label="menu"
            data-testid="menu-close-icon-button"
            onClick={() => {
              setIsNavDrawerVisible(!isNavDrawerVisible);
              snowplowTrackingForHeaderNavLinks('hide', '', '');
            }}
          >
            <Close role="button" />
          </button>

          <nav className="header__core_nav" aria-label="core menu">
            <UnstyledList>
              <li
                className={classNames('header__menu_item', {
                  'header__menu_item--selected': pathname === HeaderLinks.HOME,
                })}
              >
                <Link
                  tabIndex={tabIndex}
                  href={HeaderLinks.HOME}
                  onClick={() => snowplowTrackingForHeaderNavLinks('select', 'Home', HeaderLinks.HOME)}
                >
                  <Home role="presentation" className="header__menu_item__icon" />
                  Home
                </Link>
              </li>
              <li
                className={classNames('header__menu_item', {
                  'header__menu_item--selected': pathname.includes(HeaderLinks.LIVE),
                })}
              >
                <Link
                  tabIndex={tabIndex}
                  href={HeaderLinks.LIVE}
                  onClick={() => snowplowTrackingForHeaderNavLinks('select', 'Live TV', HeaderLinks.LIVE)}
                >
                  <Lightning role="presentation" className="header__menu_item__icon" />
                  Live TV
                </Link>
              </li>
              <li
                className={classNames('header__menu_item', {
                  'header__menu_item--selected': pathname === HeaderLinks.CATEGORIES,
                })}
              >
                <Link
                  tabIndex={tabIndex}
                  href={HeaderLinks.CATEGORIES}
                  onClick={() =>
                    snowplowTrackingForHeaderNavLinks('select', 'Categories', HeaderLinks.CATEGORIES)
                  }
                >
                  <Categories role="presentation" className="header__menu_item__icon" />
                  Categories
                </Link>
              </li>
            </UnstyledList>
          </nav>
          <nav className="header__user_nav" aria-label="user menu">
            <UnstyledList>
              <li className="header__menu_item">
                {ssoFeatureFlag && !!sdk && (
                  <a
                    href={getSSOPath('Device')}
                    onClick={() =>
                      snowplowTrackingForHeaderNavLinks('select', 'Connect your TV', getSSOPath('Device'))
                    }
                  >
                    <Tv role="presentation" className="header__menu_item__icon" />
                    Connect your TV
                  </a>
                )}
              </li>
              <li
                className={classNames('header__menu_item', {
                  'header__menu_item--selected': isDesktop && isProfileMenuVisible,
                })}
              >
                {isLoggedIn ? (
                  <>
                    {isDesktop && (
                      <button
                        type="button"
                        data-testid="profile-menu-button"
                        onClick={() => setIsProfileMenuVisible(!isProfileMenuVisible)}
                      >
                        {userFirstName}
                        <Chevron
                          className={classNames('header__styled_chevron', {
                            'header__styled_chevron--visible': isProfileMenuVisible,
                          })}
                          role="presentation"
                        />
                      </button>
                    )}
                    <ul
                      className={classNames('header__profile_menu', {
                        'header__profile_menu--visible': isProfileMenuVisible,
                      })}
                      data-testid="profile-menu"
                    >
                      <li className="header__menu_item">
                        {ssoFeatureFlag && (
                          <a
                            title="Go to the My Profile page"
                            href={getSSOPath('Profile')}
                            onClick={() =>
                              snowplowTrackingForHeaderNavLinks('select', 'My Profile', getSSOPath('Profile'))
                            }
                          >
                            <Profile role="presentation" className="header__menu_item__icon" />
                            {isDesktop ? 'My profile' : userFirstName}
                          </a>
                        )}
                      </li>
                      <li className="header__menu_item">
                        {ssoFeatureFlag && (
                          <Link
                            title="Go to the Watch History page"
                            href="/history"
                            onClick={() =>
                              snowplowTrackingForHeaderNavLinks('select', 'Watch History', '/history')
                            }
                          >
                            <Time role="presentation" className="header__menu_item__icon" />
                            Watch history
                          </Link>
                        )}
                      </li>
                      <li className="header__menu_item">
                        <a
                          title="Log out"
                          href={getSSOPath('Logout')}
                          onClick={() =>
                            snowplowTrackingForHeaderNavLinks('select', 'Log Out', getSSOPath('Logout'))
                          }
                        >
                          <Exit role="presentation" className="header__menu_item__icon" />
                          Log out
                        </a>
                      </li>
                    </ul>
                  </>
                ) : (
                  !!sdk && (
                    <a
                      href={getSSOPath('Login')}
                      onClick={() =>
                        snowplowTrackingForHeaderNavLinks('select', 'Log In', getSSOPath('Login'))
                      }
                    >
                      <Profile role="presentation" className="header__menu_item__icon" />
                      Log in
                    </a>
                  )
                )}
              </li>
            </UnstyledList>
          </nav>
        </div>
        <AnimatePresence>
          {(isNavDrawerVisible || (isDesktop && isProfileMenuVisible)) && (
            <motion.div
              className="header__drawer_overlay"
              onClick={() => {
                setIsNavDrawerVisible(false);
                setIsProfileMenuVisible(false);
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: isDesktop ? 0 : 0.8 }}
              transition={{ duration: 0.2, ease: 'easeOut' }}
              exit={{ opacity: 0 }}
              data-testid="nav-overlay"
            />
          )}
        </AnimatePresence>
        <a
          className="header__anchor_link"
          href="/search"
          aria-label="search"
          data-testid="search-anchor"
          onClick={() => snowplowTrackingForHeaderNavLinks('select', 'Search', '/search')}
        >
          <Search role="button" />
        </a>
      </header>
      <div
        className={classNames('header__background_gradient', {
          'header__background_gradient--extended': tabbedGrid.isPositionFixed,
        })}
        ref={headerGradientRef}
      />
    </>
  );
};

export default Header;
