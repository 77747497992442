export enum Breakpoints {
  mobile = '0px',
  tabletPortrait = '600px',
  tabletLandscape = '900px',
  desktop = '1200px',
  desktopLarge = '2000px',
  desktopXLarge = '2400px',
}

const theme = {
  breakpoints: Breakpoints,
};

export default theme;
