import './Layout.scss';
import { usePathname } from 'next/navigation';
import classNames from 'classnames';
import Header from '../header/Header';
import Footer from '../footer/Footer';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  userFirstName: string | undefined;
  isLoggedIn: boolean;
  fullWidth?: boolean;
};

const Layout = ({ children, userFirstName, isLoggedIn, fullWidth }: Props) => (
  <div className="layout__container">
    <Header pathname={usePathname() ?? ''} userFirstName={userFirstName} isLoggedIn={isLoggedIn} />
    {/* TODO: Add a skip link here (https://developer.mozilla.org/en-US/docs/Learn/Accessibility/HTML#skip_links) */}
    <main className={classNames('layout__main', { 'layout__main--fullwidth': fullWidth })}>{children}</main>
    <Footer isLoggedIn={isLoggedIn} />
  </div>
);

export default Layout;
